import store from '@/state/store';

import { http } from './http';
import { fetchPaginatedData } from './pagination';

export default {
  // Authentication
  async login(username, password) {
    const response = await http.post('/v1/login/', {
      'username': username,
      'password': password,
    }, {
      headers: { 'Authorization': '' }
    });
    const token = response.data.token;

    http.defaults.headers.common['Authorization'] = `Token ${token}`;
    await store.dispatch('login', token);
  },
  async logout() {
    try {
      await http.post(`/v1/logout/`);
    } finally {
      http.defaults.headers.common['Authorization'] = '';
      await store.dispatch('logout');
    }
  },

  // Signatories
  async listSignatories({ ...paginationParams } = {}) {
    return await fetchPaginatedData('/v1/signatories/', paginationParams)
  },
  async createSignatory({ displayName, legalName, email, language }) {
    const response = await http.post(`/v1/signatories/`, {
      display_name: displayName,
      legal_name: legalName,
      email,
      language,
    });
    return response.data;
  },
  async getSignatory(signatoryId) {
    const response = await http.get(`/v1/signatories/${signatoryId}/`);
    return response.data;
  },
  async listSignatorySigningMethods(signatoryId, { ...paginationParams } = {}) {
    return await fetchPaginatedData(`/v1/signatories/${signatoryId}/signing-methods/`, paginationParams);
  },
  async listSignatoryEnvelopes(signatoryId, { ...paginationParams } = {}) {
    return await fetchPaginatedData(`/v1/signatories/${signatoryId}/envelopes/`, paginationParams);
  },
  async addOTPSigningMethod(signatoryId, { scheme, destination }) {
    await http.post(`/v1/signatories/${signatoryId}/signing-methods/`, {
      scheme,
      otp_destination: destination,
    });
  },

  // Document
  async listEnvelopes({ ...paginationParams } = {}) {
    return await fetchPaginatedData('/v1/envelopes/', paginationParams);
  },
  async listMyEnvelopes({ ...paginationParams } = {}) {
    return await fetchPaginatedData('/v1/envelopes/', {
      ...paginationParams,
      created_by: store.state.currentUser.id,
    })
  },
  async createEnvelope({ name, file }, { ...httpConfig } = {}) {
    let formData = new FormData();
    formData.append('name', name);
    formData.append('file', file);

    const response = await http.post(`/v1/envelopes/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 10 * 60000,
      ...httpConfig,
    });
    return response.data;
  },
  async getEnvelope(envelopeId) {
    const response = await http.get(`/v1/envelopes/${envelopeId}/`);
    return response.data;
  },
  async listEnvelopeSignatures(envelopeId, { ...paginationParams } = {}) {
    return await fetchPaginatedData(`/v1/envelopes/${envelopeId}/signatures/`, paginationParams);
  },
  async listEnvelopeActivities(envelopeId, { ...paginationParams } = {}) {
    return await fetchPaginatedData(`/v1/envelopes/${envelopeId}/activities/`, paginationParams);
  },
  async publishEnvelope(envelopeId, { message, expires_at, signingLink }) {
    await http.post(`/v1/envelopes/${envelopeId}/publish/`, {
      message: message,
      expires_at: expires_at,
      signing_link: signingLink,
    });
  },
  async addSignatureToEnvelope(envelopeId, { signingMethod }) {
    await http.post(`/v1/envelopes/${envelopeId}/signatures/`, {
      method: signingMethod,
    });
  },
  async removeSignatureFromEnvelope(envelopeId, signatureId) {
    await http.delete(`/v1/envelopes/${envelopeId}/signatures/${signatureId}/`);
  },
  async sendSigningRequest(envelopeId, signatureId) {
    await http.post(`/v1/envelopes/${envelopeId}/signatures/${signatureId}/send-invite/`);
  },
  async revokeEnvelope(envelopeId, { reason }) {
    await http.post(`/v1/envelopes/${envelopeId}/revoke/`, {
      reason,
    });
  },
  async deleteEnvelope(envelopeId) {
    await http.delete(`/v1/envelopes/${envelopeId}/`);
  },
  async generateSigningCertificate(envelopeId) {
    await http.post(`/v1/envelopes/${envelopeId}/certificate/`);
  },

  // Signature
  async getEnvelopeBySigningSecret(signingSecret) {
    const response = await http.get(`/v1/sign/${signingSecret}/`, {
      headers: {'Authorization': ''},
    });
    return response.data;
  },
  async sendSigningChallenge(signingSecret) {
    await http.post(`/v1/sign/${signingSecret}/send_challenge/`, {
      headers: {'Authorization': ''},
    });
  },
  async sendSigningChallengeResponse(signingSecret, { response, geoLat, geoLng }) {
    await http.post(`/v1/sign/${signingSecret}/send_response/`, {
      response: response,
      location_latitude: geoLat,
      location_longitude: geoLng,
    }, {
      headers: {'Authorization': ''},
    });
  },

  // Audit
  async listEventLogs({ ...paginationParams } = {}) {
    return await fetchPaginatedData('/v1/audit/events/', paginationParams);
  },
  async listCorrespondenceLogs({ ...paginationParams } = {}) {
    return await fetchPaginatedData('/v1/audit/correspondences/', paginationParams);
  },

  // Users
  async listUsers({ ...paginationParams } = {}) {
    return await fetchPaginatedData('/v1/users/', paginationParams);
  },
  async createUser({ email, firstName, lastName, phoneNumber, role }) {
    const response = await http.post(`/v1/users/`, {
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      role: role,
    });
    return response.data;
  },
  async getUser(userId) {
    const response = await http.get(`/v1/users/${userId}/`);
    return response.data;
  },
  async sendUserWelcomeEmail(userId) {
    await http.post(`/v1/users/${userId}/send-welcome-email/`);
  },
  async setUserPassword(userId, newPassword) {
    await http.post(`/v1/users/${userId}/set-password/`, {
      new_password: newPassword,
    });
  },
  async forceChangeUserPassword(userId) {
    await http.post(`/v1/users/${userId}/force-change-password/`);
  },
  async reactivateUser(userId) {
    await http.post(`/v1/users/${userId}/reactivate/`);
  },


  // Current User
  async getCurrentUser() {
    const response = await http.get('/v1/current-user/');
    return response.data;
  },
  async changeCurrentUserPassword(oldPassword, newPassword) {
    await http.post('/v1/current-user/change-password/', {
      old_password: oldPassword,
      new_password: newPassword,
    });
  },
}
